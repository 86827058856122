* {
    margin: 0;
    /* outline: 1px solid red; */
}

body {
    box-sizing: border-box;
}

.drink-container {
    display: flex;
    flex-direction: row;
}

.detail-title {
    text-align: center;
    margin-top: 50px;
    font-size: 50px;
    font-family: 'Lora', serif;
    color: rgb(6, 51, 6);
    margin-bottom: 25px;
    width: 100%;
    
}

.detail-img {
    width: 25%;
    height: auto;
    margin-left: 30px;
    border-radius: 10px;
    border: 4px solid rgb(6, 51, 6);
}

.details-container {
    display: flex;
    flex-direction: column;
    gap : 25px;
    margin-left: 50px;
}

.detail {
    font-family: 'Lora', serif;
    font-size: 25px;
    width: 75%;
    font-weight: 200;
    border-bottom: 2px solid rgb(6, 51, 6);
    padding-bottom: 50px;
}

.back-btn {
    border: 2px solid black;
    border-radius: 10px;
    background-color: rgb(6, 51, 6);
    width: 100px;
    height: 50px;
    margin-top: 100px;
    color: white;
    font-size: 25px;
    text-align: center;
    text-decoration: none;
    padding: 10px;
    font-family: 'Lora', serif;
    cursor: pointer;
}






@media only screen and (max-width: 1300px) {

    .detail-img {
        width: 300px;
        height: 300px;
    }

    .detail-title {
        width: 100%;
        margin-bottom: 75px;
    }

    .back-btn {
        margin-top: 20px;
    }

}

@media only screen and (max-width: 1200px) {

    .detail-img {
        height: 200px;
        width: 200px;
        margin-left: 55px;
    }
    
    .detail-title {
        width: 100%;
        margin-bottom: 100px;
    }
    
    .detail {
        width: 87%;
    }
   
    }
    
    @media only screen and (max-width: 800px) {

        .detail-title {
            width: 100%;
        }
    
        .detail-img {
            width: 300px;
            height: 300px;
            margin-bottom: 50px;
            margin-left: 0px;
        }
    
        .drink-container {
            flex-direction: column;
            align-items: center;
        }
    
    }

    @media only screen and (max-width: 500px) {

        .detail-title {
            font-size: 36px;
            width: 100%;
        }
    
    
        .detail-img {
            width: 200px;
            height: 200px;
            margin-bottom: 25px;
            margin-left: 1px;
        }
    
        .drink-container {
            flex-direction: column;
            align-items: center;
        }
    
        .detail {
            font-size: 18px;
            width: 90%;
        }
    
        .back-btn {
            margin-top: 25px;
            height: 30px;
            padding: 3px;
            font-size: 17px;
        }
    
    
    }
    
    
    

    @media only screen and (max-width: 350px) {

        .detail-title {
            font-size: 25px;
            width: 100%;
        }
    
        .detail-img {
            width: 200px;
            height: 200px;
            margin-left: 40px;
            margin-bottom: 51px;
            margin-left: 1px;
        }
    
        .drink-container {
            flex-direction: column;
        }
    
        .detail {
            font-size: 11px;
        }
    
    }
    