* {
    margin: 0;
    /* outline: 1px solid red; */
}

body {
    box-sizing: border-box;
}

.background {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("https://images.unsplash.com/photo-1551024709-8f23befc6f87?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1257&q=80"), no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    height: 100vh;
}

.bolded-italic {
    font-weight: 800;
    font-style: italic;
}

.header-title {
    font-family: 'Lora', serif;
    color: white;
    font-size: 6em;
    text-align: center;
    padding-top: 100px;
}

.header-description {
    font-family: 'Lora', serif;
    color: white;
    font-size: 3em;
    text-align: center;
    margin-top: 150px;
    font-weight: 100;
}

@media only screen and (max-width: 1280px) {

    .header-title {
        font-size: 4em;
        padding-top: 57px;
    }

    .header-description {
        font-size: 3em;
    }

}



@media only screen and (max-width: 800px) {

 .header-title {
    font-size: 3em;
    padding-top: 34px;
}

.header-description {
    font-size: 2em;
    margin-top: 195px;
}

}


@media only screen and (max-width: 500px) {
    .header-title {
        font-size: 31px;
    }

    .header-description {
        font-size: 20px;
        margin-top: 175px;
    }

}

@media only screen and (max-width: 350px) {

    .header-title {
        font-size: 20px;
    }

    .header-description {
        margin-top: 85px;
    }

}