* {
    margin: 0;
}

body {
    box-sizing: border-box;
    background-color: rgb(224, 216, 216);
}

.random-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
}

.detail-link {
    text-decoration: none;
}

.random-title {
    text-align: center;
    margin-top: 20px;
    font-size: 5em;
    font-family: 'Lora', serif;
    color: rgb(6, 51, 6);
}

.random-name {
    font-family: 'Lora', serif;
    color: rgb(6, 51, 6);
    font-size: 45px;
}

.random-img {
    width: 500px;
    height: 500px;
    text-align: center;
    margin-top: 10px;
    border-radius: 7px;
}

.generate-btn {
    margin-top: 20px;
    height: 75px;
    width: 400px;
    background-image: linear-gradient(rgb(6, 51, 6), green);
    color: white;
    font-family: 'Lora', serif;
    font-size: 25px;
    cursor: pointer;
    letter-spacing: 2px;
    font-weight: 800;
}

@media only screen and (max-width: 1280px) {

    .random-title {
        font-size: 4em;
    }
}

@media only screen and (max-width: 800px) {

    .random-title {
        font-size: 3em;
    }

    .random-name {
        font-size: 35px;
    }

}

@media only screen and (max-width: 650px) {

    .random-title{
        font-size: 2em;
}

    .random-name {
        font-size: 25px;
    }

    .random-img {
        width: 300px;
        height: 300px;
    }

    .generate-btn {
        width: 175px;
        height: 50px;
        font-size: 14px;
    }

}

@media only screen and (max-width: 355px) {

    .random-title {
        font-size: 1em;
    }

    .random-name {
        font-size: 18px;
    }

    .random-img {
        height: 250px;
        width: 250px;
    }

    .generate-btn {
        height: 30px;
        font-size: 9px;
    }

}