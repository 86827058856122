* {
    margin: 0;
    /* outline: 1px solid red; */
}

body {
    box-sizing: border-box;
}

.navbar {
    background-image: linear-gradient(rgb(6, 51, 6), green);
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.nav-title {
    color: white;
    font-family: 'Lora', serif;
    font-size: 45px;
    font-weight: 400;
}

.link-style {
    text-decoration: none;
    color: white;
    font-size: 25px;
    font-family: 'Lora', serif;
}

.background {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("https://images.unsplash.com/photo-1551024709-8f23befc6f87?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1257&q=80"), no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    /* height: 90vh; */
}


@media only screen and (max-width: 1350px) {

.link-style {
    font-size: 18px;
}

.nav-title {
    font-size: 18px;
}

}

@media only screen and (max-width: 1200px) {
        .link-style {
            font-size: 15px;
        }
    }




@media only screen and (max-width: 800px) {
    .link-style {
        font-size: 12px;
    }

    .nav-title {
        font-size: 12px;
    }

}



@media only screen and (max-width: 575px) {

    .link-style {
        font-size: 8px;
    }

}



@media only screen and (max-width: 500px) {

    .link-style {
        font-size: 8px;
    }

    .nav-title {
        font-size: 8px;
    }

    .navbar {
        height: 75px;
        justify-content: space-evenly;
    }
}

@media only screen and (max-width: 400px) {

    .link-style {
        font-size: 8px;
    }

 
}

